import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import { formatCurrency } from "../../helpers/utils";
interface EchartsGraphPieProps {
  data: { [key: string]: number };
}

export const EchartsGraphPie: React.FC<EchartsGraphPieProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const myChartRef = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      myChartRef.current = echarts.init(chartRef.current);
    }

    const chartData = Object.entries(data).map(([name, value]) => ({
      name,
      value,
    }));

    const totalValue = chartData.reduce((sum, item) => sum + item.value, 0);

    const option: echarts.EChartsOption = {
      title: {
        text: "",
        subtext: "",
        left: "center",
        textStyle: {
          color: "#ffffff",
          fontSize: 24,
          fontWeight: "bold",
        },
        subtextStyle: {
          color: "#ffffff",
          fontSize: 16,
        },
      },
      tooltip: {
        trigger: "item",
        backgroundColor: "#2c3e50",
        borderColor: "#2980b9",
        borderWidth: 1,
        textStyle: {
          color: "#ecf0f1",
          fontSize: 12,
        },
      },
      legend: {
        orient: "horizontal",
        left: "left",
        textStyle: {
          color: "#ffffff",
          fontSize: 10,
          fontWeight: "normal",
        },
      },
      series: [
        {
          name: "Por Cliente",
          type: "pie",
          radius: "50%",
          data: chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0)",
            },
          },
          label: {
            formatter: (params) => {
              const percentage = (
                (Number(params.value) / totalValue) *
                100
              ).toFixed(2);
              return `${formatCurrency(
                Number(params.value)
              )}  (${percentage}%)`;
            },
            position: "outside",
            color: "#ffffff",
            fontSize: 14,
            fontWeight: "normal",
          },
        },
      ],
    };

    myChartRef.current?.setOption(option);

    const handleResize = () => {
      myChartRef.current?.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      myChartRef.current?.dispose();
    };
  }, [data]);

  return <div ref={chartRef} style={{ width: "100%", height: "40vh" }} />;
};
