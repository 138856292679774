import { useRef, useEffect } from "react";
import * as echarts from "echarts";
import { formatCurrency, getFormattedDates } from "../../helpers/utils";

export const EchartsGraphStackArea = ({
  startDate,
  endDate,
  clientDateCount,
  rangeInDays,
  title,
}: {
  startDate: Date;
  endDate: Date;
  clientDateCount: any;
  rangeInDays: number;
  title: string;
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current);

      const dates: any[] = Object.values(clientDateCount);
      const allDates = dates.flatMap((clientData) => Object.keys(clientData));
      const uniqueDates = Array.from(new Set(allDates)).sort();

      const formattedDates = getFormattedDates(uniqueDates, rangeInDays);

      const series = Object.keys(clientDateCount).map((clientId) => {
        const clientData = clientDateCount[clientId];
        return {
          name: `${clientId}`,
          type: "line",
          stack: "total",
          areaStyle: {},
          emphasis: {
            focus: "series",
          },
          data: uniqueDates.map((dateStr) => clientData[dateStr] || 0),
        };
      });

      const option = {
        backgroundColor: "rgb(0, 0, 0, 0.1)",
        title: {
          text: `${title}`,
          left: "center",
          textStyle: {
            color: "#ffffff",
            fontSize: 18,
          },
        },
        legend: {
          top: "bottom",
          left: "center",
          textStyle: {
            color: "#ffffff",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          backgroundColor: "#2c3e50",
          borderColor: "#2980b9",
          borderWidth: 1,
          textStyle: {
            color: "#ffffff",
          },
          formatter: (params: any) => {
            let content = `<div><strong>${params[0].axisValue}</strong></div>`;
            params.forEach((item: any) => {
              content += `
                <div style="margin: 5px 0;">
                  <span style="display:inline-block;width:10px;height:10px;background-color:${
                    item.color
                  };margin-right:5px;"></span>
                  ${item.seriesName}: ${formatCurrency(item.value)}
                </div>
              `;
            });
            return content;
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          top: "15%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: formattedDates,
          axisLabel: {
            color: "#ffffff",
            fontSize: 12,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#ffffff",
            fontSize: 12,
          },
          splitLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        series,
      };

      chartInstance.current.setOption(option);
    }

    const handleResize = () => {
      chartInstance.current?.resize();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      chartInstance.current?.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [clientDateCount, rangeInDays, startDate, endDate]);

  return <div ref={chartRef} style={{ width: "100%", minHeight: "55vh" }} />;
};
