import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { EchartsPie } from "./components/EchartsPie";
import { ReactComponent as BarIcon } from "../../../../Assets/common/images/barGraphIcon.svg";
import { ReactComponent as AreaIcon } from "../../../../Assets/common/images/stackArea.svg";
import EchartsStackBar from "./components/EchartsStackBar";
import EchartsStackArea from "./components/EchartsStackArea";
import { IndicatorButton } from "./components/IndicatorButton";
import { changeCurrentProduct } from "../../../common/commonSlice";
import {
  changeActiveComponent,
  getGeneralAdvisors,
  selectActiveDashboardComponent,
  selectCurrentFilteredDashboard,
  selectDataIndicatorsAdvisors,
  selectDataStackBarAdvisors,
  selectLoadingGeneralAdvisors,
  selectNameAdvisor,
} from "../DashboardSlice";
import { capitalize } from "../../../../helpers/utils";
import { DataGeneralAdvisor, GeneralAdvisorData } from "../DashboardApi";
import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from "react-loading-overlay-ts";

export const Advisors = () => {
  const dispatch = useAppDispatch();
  const activeComponent = useAppSelector(selectActiveDashboardComponent);
  const filters = useAppSelector(selectCurrentFilteredDashboard);
  const nameAdvisor = useAppSelector(selectNameAdvisor);
  const dataAdvisor = useAppSelector(selectDataIndicatorsAdvisors);
  const dataStackBar = useAppSelector(selectDataStackBarAdvisors);
  const loadingData = useAppSelector(selectLoadingGeneralAdvisors);
  const [isShowPercentage, setShowPercentage] = useState(false);
  const [showNameAdvisor, setShowNameAdvisor] = useState(true);
  const [showListAdvisors, setShowListAdvisors] = useState(true);
  const [changeGraph, setChangeGraph] = useState(false);

  useEffect(() => {
    dispatch(changeCurrentProduct("Dashboard"));

    if (activeComponent != "Advisors") {
      dispatch(changeActiveComponent("Advisors"));
    }
  }, [activeComponent]);

  useEffect(() => {
    dispatch(
      getGeneralAdvisors({
        filter: filters,
      })
    );
  }, [filters]);

  function isDataGeneralAdvisor(
    advisor: DataGeneralAdvisor | null | undefined
  ): advisor is GeneralAdvisorData {
    return !!advisor && "data" in advisor;
  }

  let dataPieGeneralAdvisor: { [key: string]: number } = {};
  let dataPieFlowStatus: { [key: string]: number } = {};

  if (dataAdvisor && isDataGeneralAdvisor(dataAdvisor)) {
    dataPieGeneralAdvisor = dataAdvisor.data.reduce((acc, advisor) => {
      acc[advisor._id.asesorId] = advisor.count;
      return acc;
    }, {} as { [key: string]: number });
  } else {
    dataPieGeneralAdvisor =
      dataAdvisor?.flowId.reduce((acc, advisor) => {
        acc[advisor._id.hyperFlowId] = advisor.count;
        return acc;
      }, {} as { [key: string]: number }) ?? {};

    dataPieFlowStatus =
      dataAdvisor?.flowStatus.reduce((acc, advisor) => {
        acc[advisor._id.flowStatus] = advisor.count;

        if (advisor._id.acceptanceStatus !== "NONE") {
          acc[advisor._id.acceptanceStatus] = advisor.count;
        }

        return acc;
      }, {} as { [key: string]: number }) ?? {};
  }

  const getSortedAdvisors = (dataClients: Record<string, number>) => {
    const sortedClients = Object.entries(dataClients)
      .sort(([, valueA], [, valueB]) => valueB - valueA)
      .slice(0, 5);
    return sortedClients;
  };

  const sortedClients = getSortedAdvisors(dataPieGeneralAdvisor);

  const detailAdvisors = () => {
    return (
      <>
        <div
          className="primary-color pt-3 mx-2 bg-black px-3 bg-opacity-10 mt-3 rounded d-flex-column"
          style={{ minHeight: "60%", paddingBottom: "10px" }}
        >
          <div>
            <div className="d-flex justify-content-between">
              <p className="fs-6 fw-semibold px-5 m-0">
                Indicadores Especificos
              </p>
              <p className="fs-6 fw-semibold px-5 m-0">
                Asesor:{" "}
                {nameAdvisor && (
                  <span className="fw-bold"> {capitalize(nameAdvisor)}</span>
                )}
              </p>
            </div>
            <div className="d-flex justify-content-center bg-black p-2 bg-opacity-10 res-indicadores__especificos ">
              <div className="d-flex-column w-50 rounded-3 res-graph__pie">
                <EchartsPie
                  data={dataPieGeneralAdvisor}
                  showPercentege={isShowPercentage}
                  showName={!showNameAdvisor}
                  showList={showListAdvisors}
                  title="Flujos"
                />
              </div>
              <div className="d-flex-column  w-50 rounded-3 res-graph__pie">
                <EchartsPie
                  data={dataPieFlowStatus}
                  showPercentege={isShowPercentage}
                  showName={!showNameAdvisor}
                  showList={showListAdvisors}
                  title="Estado"
                />
              </div>
              <div className="d-flex flex-column">
                <div>
                  <button
                    className="w-100 border-0 rounded text-xx-small bg-black p-2 bg-opacity-25 primary-color mb-3"
                    style={{ maxWidth: "150px" }}
                    onClick={() => {
                      setShowListAdvisors(!showListAdvisors);
                    }}
                  >
                    {showListAdvisors ? "Ocultar lista" : "Visualizar lista"}
                  </button>
                </div>
                <div>
                  <button
                    className="w-100 border-0 rounded text-xx-small bg-black p-2 bg-opacity-25 primary-color mb-3"
                    style={{ width: "150px" }}
                    onClick={() => {
                      setShowNameAdvisor(!showNameAdvisor);
                    }}
                  >
                    {showNameAdvisor
                      ? "No visualizar nombres"
                      : "Visualizar nombres"}
                  </button>
                </div>
                <div className="d-flex justify-content-center">
                  <IndicatorButton
                    isActive={!isShowPercentage}
                    onClick={() => setShowPercentage(false)}
                    label="#"
                    isLeft
                  />
                  <IndicatorButton
                    isActive={isShowPercentage}
                    onClick={() => setShowPercentage(true)}
                    label="%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="primary-color pt-3 mx-2 bg-black px-3 bg-opacity-10 mt-3 rounded">
          <p className="text-center fw-semibold m-0">
            Comportamiento por fecha:{" "}
            <span className="fw-bold">
              {nameAdvisor && capitalize(nameAdvisor)}
            </span>
          </p>
          <div className="d-flex align-align-items-center mb-2">
            <IndicatorButton
              isActive={!changeGraph}
              onClick={() => setChangeGraph(false)}
              label=""
              icon={<BarIcon />}
              isLeft
            />

            <IndicatorButton
              isActive={changeGraph}
              onClick={() => setChangeGraph(true)}
              label=""
              icon={<AreaIcon />}
            />
          </div>
          {!changeGraph && (
            <EchartsStackBar
              dataAdvisorsStackBar={dataStackBar ?? { data: [] }}
            />
          )}
          {changeGraph && (
            <EchartsStackArea
              dataAdvisorsStackBar={dataStackBar ?? { data: [] }}
            />
          )}
        </div>
      </>
    );
  };

  const generalIndicators = () => {
    return (
      <>
        <div
          className="primary-color pt-3 mx-2 bg-black px-3 bg-opacity-10 mt-3 rounded d-flex-column"
          style={{ minHeight: "60%", paddingBottom: "10px" }}
        >
          <p className="fs-6 fw-bold px-5">Indicadores Generales</p>
          <div className="d-flex gap-lg-4 justify-content-center res-indicadores__generales">
            <div className="d-flex bg-black p-2 bg-opacity-10  w-75 rounded-3 res-graph__pie">
              <EchartsPie
                data={dataPieGeneralAdvisor}
                showPercentege={isShowPercentage}
                showName={showNameAdvisor}
                showList={showListAdvisors}
                title="asesores"
              />
              <div className="d-flex-column mx-2 gap-3">
                <div>
                  <button
                    className="w-100 border-0 rounded text-xx-small bg-black p-2 bg-opacity-25 primary-color mb-3"
                    onClick={() => {
                      setShowListAdvisors(!showListAdvisors);
                    }}
                  >
                    {showListAdvisors ? "Ocultar lista" : "Visualizar lista"}
                  </button>
                </div>
                <div>
                  <button
                    className="w-100 border-0 rounded text-xx-small bg-black p-2 bg-opacity-25 primary-color mb-3"
                    onClick={() => {
                      setShowNameAdvisor(!showNameAdvisor);
                    }}
                  >
                    {showNameAdvisor
                      ? "No visualizar nombres"
                      : "Visualizar nombres"}
                  </button>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    <IndicatorButton
                      isActive={!isShowPercentage}
                      onClick={() => setShowPercentage(false)}
                      label="#"
                      isLeft
                    />
                  </div>

                  <div>
                    <IndicatorButton
                      isActive={isShowPercentage}
                      onClick={() => setShowPercentage(true)}
                      label="%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-black p-2 bg-opacity-10 w-25 h-50 rounded-3">
              <p className="text-white text-center fw-semibold fs-6">
                Top Asesores
              </p>
              <ol style={{ fontSize: "12px" }}>
                {sortedClients.map(([name], index) => (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    {capitalize(name)}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
        <div className="primary-color pt-3 mx-2 bg-black bg-opacity-10 mt-3 rounded p-2">
          <div className="d-flex align-align-items-center mb-2">
            <IndicatorButton
              isActive={!changeGraph}
              onClick={() => setChangeGraph(false)}
              label=""
              icon={<BarIcon />}
              isLeft
            />
            <IndicatorButton
              isActive={changeGraph}
              onClick={() => setChangeGraph(true)}
              label=""
              icon={<AreaIcon />}
            />
          </div>
          {!changeGraph && (
            <EchartsStackBar
              dataAdvisorsStackBar={dataStackBar ?? { data: [] }}
            />
          )}
          {changeGraph && (
            <EchartsStackArea
              dataAdvisorsStackBar={dataStackBar ?? { data: [] }}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div className="h-100 overflow-auto">
      <LoadingOverlay
        active={loadingData === "pending"}
        spinner={<ClipLoader color="white" size="150px" />}
      >
        {filters.asesorId ? detailAdvisors() : generalIndicators()}
      </LoadingOverlay>
    </div>
  );
};
