import fileDownload from "js-file-download";
import axiosApiInstance from "../../../helpers/interceptor";
import { formatDateStandar } from "../../../helpers/utils";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const getGeneralInidicators = "/billing/analytics";
const getDataClient = "/billing/activeClients";
const getGenerateReport = "/billing/csvAnalytics";
const getGeneralConfig = "/billing/generalConfig";
const getGeneralConfigByClient = "/billing/clientConfig";
const updateGeneralConfig = "/billing/updateGeneralConfig";
const getReportOptionsPath = (clientId: string) =>
  `/billing/reportOptions/${clientId}`;
const changeReportOptionsPath = "/billing/changeReportOptions";
const changeUnbillablePath = "/billing/changeUnbillable";
const billingOptionsPath = (clientId: string) =>
  `/billing/billingOptions/${clientId}`;
const billClientPath = "/billing/billClient";
const confirmBillPath = "/billing/confirmBill";

export const getGeneralIndicatorsAPI = async (
  params: FilteredRequest | null
): Promise<{
  data: GeneralIndicatorsResponse | null;
  error: string;
}> => {
  try {
    const resp = await axiosApiInstance.post(getGeneralInidicators, params);

    if (resp.status !== 200) {
      return {
        data: null,
        error: resp.data.message,
      };
    } else {
      return {
        data: resp.data,
        error: "",
      };
    }
  } catch (err) {
    return {
      data: null,
      error: `Error fetching general indicators ${err}`,
    };
  }
};

export const getDataClientsAPI = async (): Promise<{
  data: ClientsResponse | null;
  error: string;
}> => {
  try {
    const resp = await axiosApiInstance.get(getDataClient);

    if (resp.status !== 200) {
      return {
        data: null,
        error: resp.data.message,
      };
    } else {
      return {
        data: resp.data,
        error: "",
      };
    }
  } catch (err) {
    return {
      data: null,
      error: `Error fetching general indicators ${err}`,
    };
  }
};

export const generateDetailReportAPI = async (
  params: FilteredRequest | null
) => {
  try {
    const dataParams = {
      ...params,
    };

    const { data } = await axiosApiInstance.post(getGenerateReport, dataParams);
    const url = `data:text/csv;base64,${data.csvBase64}`;

    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        fileDownload(blob, `${formatDateStandar(data.dateGenerated)}.csv`);
      });
    return data;
  } catch {
    return null;
  }
};

export const getGeneralConfigAPI = async (): Promise<{
  data: GeneralConfig | null;
  error: string;
}> => {
  try {
    const resp = await axiosApiInstance.get(getGeneralConfig);
    if (resp.status !== 200) {
      return {
        data: null,
        error: resp.data.message,
      };
    } else {
      const generalConfig: GeneralConfig = {
        ...resp.data,
      };

      return {
        data: generalConfig,
        error: "",
      };
    }
  } catch (err) {
    return {
      data: null,
      error: `Error fetching general config ${err}`,
    };
  }
};

export const getGeneralConfigByClientAPI = async (
  clientId: string
): Promise<{
  clientConfig: GeneralConfigByClient | null;
  error: string;
}> => {
  try {
    const resp = await axiosApiInstance.get(
      `${getGeneralConfigByClient}/${clientId}`
    );
    if (resp.status !== 200) {
      return {
        clientConfig: null,
        error: resp.data.message,
      };
    } else {
      const clientConfig: GeneralConfigByClient = {
        ...resp.data,
      };
      return {
        clientConfig,
        error: "",
      };
    }
  } catch (err) {
    return {
      clientConfig: null,
      error: `Error desconocido en la solicitud. ${err}`,
    };
  }
};

export const updateGeneralConfigAPI = async (value: number) => {
  try {
    const data = {
      goalValue: value,
    };
    const resp = await axiosApiInstance.post(updateGeneralConfig, data);

    if (resp.status !== 200) {
      return {
        data: null,
        error: resp.data.message,
      };
    } else {
      return {
        data: "Meta establecida",
        error: "",
      };
    }
  } catch (err) {
    return {
      data: null,
      error: `Error fetching general indicators ${err}`,
    };
  }
};

export const getReportOptionsAPI = async (clientId: string) => {
  try {
    const resp = await axiosApiInstance.get<ReportOptionsResponse>(
      getReportOptionsPath(clientId)
    );
    return resp.data;
  } catch (err) {
    return null;
  }
};

export const changeReportOptionsAPI = async (req: ChangeReportFieldsReq) => {
  try {
    const resp = await axiosApiInstance.post<ReportOptionsResponse>(
      changeReportOptionsPath,
      req
    );
    return resp.data;
  } catch (err) {
    return null;
  }
};

export const changeUnbillableAPI = async (req: ChangeUnbillableReq) => {
  try {
    await axiosApiInstance.post(changeUnbillablePath, req);
    return true;
  } catch (err) {
    return null;
  }
};

export const getBillingOptionsAPI = async (clientId: string) => {
  try {
    const resp = await axiosApiInstance.get<BillingOptions>(
      billingOptionsPath(clientId)
    );
    return resp.data;
  } catch (err) {
    return null;
  }
};

export const billClientAPI = async (req: BillInfoRequest) => {
  try {
    const resp = await axiosApiInstance.post<BillInfoResponse>(
      billClientPath,
      req
    );

    if (resp.data.reportFileBase64) {
      // dataurl of excel file
      const now = new Date();
      const formatted = format(now, "yyyy-MM-dd HH:mm:ss", { locale: es });
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${resp.data.reportFileBase64}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          fileDownload(blob, `${formatted}.xlsx`);
        });
    }
    return resp.data;
  } catch (err) {
    return null;
  }
};

export const confirmBillAPI = async (req: Bill) => {
  try {
    const resp = await axiosApiInstance.post<BillInfoResponse>(
      confirmBillPath,
      req
    );
    return resp.data;
  } catch (err) {
    return null;
  }
};

export interface GeneralIndicatorsResponse {
  total: number;
  estimated: number;
  valueToGoal: number;
  percentChange: number;
  serviceCount: Record<string, number>;
  clientCount: Record<string, number>;
  dateCount: Record<string, number>;
  serviceDateCount: Record<string, Record<string, number>>;
  clientDateCount: Record<string, Record<string, number>>;
  monthOrWeeklyStart: string;
  monthOrWeeklyEnd: string;
}

export interface FilteredRequest {
  startDate: string | null;
  endDate: string | null;
  simpleDateFilter: string | null;
  clientId: string | null;
  hfId: string | null;
  accumulated: boolean | false;
  visualizationType: string | null;
}

export interface Client {
  id: string;
  name: string;
}

export interface GeneralConfig {
  goalValue: number | null;
  previousTotals: number[] | [];
  latestEstimation: number | null;
  latestEstimationDate: string | null;
}

export interface ReportField {
  fieldName?: string;
  field?: string;
}

export interface ReportOptionsResponse {
  allOptions?: Record<string, ReportField[]>;
  clientActiveOptions?: Record<string, ReportField[]>;
}

export interface ChangeReportFieldsReq {
  clientId?: string;
  newReportFields?: Record<string, ReportField[]>;
}

export interface ChangeUnbillableReq {
  clientId?: string;
  entityId?: string;
  service?: string;
  unbillable?: boolean;
  reason?: string;
}

export interface BillingOptions {
  bills?: Bill[];
}

export interface Bill {
  id?: string; // ObjectId typically maps to a string in TypeScript
  clientId?: string;
  dateGenerated?: string;
  startDateBilling?: string;
  endDateBilling?: string;
  servicesBilled?: BilledService[];
  total?: number;
  generatedBy?: string;
  invoiceIssued?: boolean;
  siigoBillId?: string;
}

export interface BillInfoResponse {
  reports?: Record<string, Array<Record<string, any>>>;
  preFacturaBase64?: string;
  reportFileBase64?: string;
  generatedBill?: Bill;
}

export interface BilledService {
  service?:
    | "COREID"
    | "FORMS"
    | "FIRMA"
    | "FIRMA_BASIC"
    | "BLACKLIST"
    | "EXPERIAN";
  countBilled?: number;
  amMountBilled?: number; // Corrected the spelling from 'ammount' to 'amount'
  currency?: string;
  billedIds?: string[];
  ignoredIds?: string[];
}

export interface BillInfoRequest {
  dateToGenerate?: string;
  clientId?: string;
  billedBill?: Bill;
  generateInvoice?: boolean;
  generateReport?: boolean;
}

export type ClientsResponse = Client[];

export type ConstraintType =
  | "HYPERFLOW_STEP_UNBILLABLE"
  | "HYPERFLOW_STEP_BILLABLE";

export type StartBilling = "FINISHED" | "FIRST_CAPTURED" | "PARTIALLY_SIGNED";

export interface Constraint {
  constraintEntityIds: string[];
  constraintType: ConstraintType;
}

export interface Service {
  startBilling: StartBilling;
  maxBilling: number;
  unitPrice: PricingConfig;
  secondaryUnitPrice: PricingConfig;
  currency: string;
  constraints: Constraint[];
}

export interface GeneralConfigByClient {
  clientId: string;
  activeServices: string[];
  services: Record<string, Service>;
  cutoffDay: number;
  active: boolean;
}

export interface PricingConfig {
  scalable: boolean;
  pricingItems: PriceConfigItem[];
}

export interface PriceConfigItem {
  price: number;
  min: number;
  max: number;
}
