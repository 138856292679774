import axiosApiInstance from "../../../helpers/interceptor";
import { formatDateStandar } from "../../../helpers/utils";

const getDataReports = "dashboard/data_view";
const getDownloadReport = "dashboard/report_data";
const getDataStatics = "dashboard/indicators";
const getDataPie = "dashboard/indicators/data_pie";
const getDataMetrics = "dashboard/metrics";
const getDataFunnel = "dashboard/funnel_coreid";
const getDataConvertibility = "dashboard/convertibility";
const getDataFunnelHF = "dashboard/funnel_hyperflow";
const getFilterOptions = "hyperflow/filterOptions";
const getDataAdvisorGeneralPie = "dashboard/adviser_pie";
const getDataAdvisorStackBar = "dashboard/adviser_general";

const getFilteredReportApi = async (
  parameters: FilteredRequest
): Promise<{
  reportsData: ReportsFiltered | null;
  titles: ColumnsResponse | null;
  error: string;
}> => {
  try {
    const data = {
      parameters: parameters,
    };

    const response = await axiosApiInstance.post(getDataReports, data);

    if (response.status !== 200) {
      return {
        reportsData: null,
        titles: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const reportsData: ReportsFiltered = {
        reportData: response.data.result,
        numPages: response.data.total_pages,
        currentPage: parameters.page,
      };

      const titles: ColumnsResponse = {
        columns: response.data.columns,
      };

      return { reportsData, titles, error: "" };
    }
  } catch (err) {
    return {
      reportsData: null,
      titles: null,
      error: `Error en la solicitud. ${err}`,
    };
  }
};

const downloadReportAPI = async (
  idClient: string,
  requestData: FilteredRequest
) => {
  try {
    const data = {
      parameters: {
        startDate: requestData.startDate,
        endDate: requestData.endDate,
      },
    };
    const response = await axiosApiInstance.post(getDownloadReport, data, {
      responseType: "blob",
    });
    let dateCurrent = new Date();
    let date = dateCurrent.toString();
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = urlBlob;
    link.setAttribute("download", `INFORME ${formatDateStandar(date)}.xlsx`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(urlBlob);

    return true;
  } catch (error) {
    console.error("Error al descargar el informe:", error);
    return false;
  }
};

const getStatisticsDataAPI = async (
  params: FilteredRequest
): Promise<{
  statistics: DataStatistics | null;
  error: string;
}> => {
  try {
    const data = {
      parameters: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    };
    const response = await axiosApiInstance.post(getDataStatics, data);

    if (response.status != 200) {
      return {
        statistics: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const statistics: DataStatistics = {
        statisticsData: response.data.counters,
      };

      return { statistics, error: "" };
    }
  } catch (error) {
    return {
      statistics: null,
      error: `Error en la solicitud. ${error}`,
    };
  }
};

const getPieDataAPI = async (
  params: FilteredRequest
): Promise<{
  pie: DataPie | null;
  error: string;
}> => {
  try {
    const data = {
      parameters: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    };
    const response = await axiosApiInstance.post(getDataPie, data);

    if (response.status != 200) {
      return {
        pie: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const pie: DataPie = {
        data: response.data.data,
      };

      return { pie, error: "" };
    }
  } catch (error) {
    return {
      pie: null,
      error: `Error en la solicitud. ${error}`,
    };
  }
};

const getGaugeDataAPI = async (
  params: FilteredRequest
): Promise<{ gauge: DataGauge | null; error: string }> => {
  try {
    const data = {
      parameters: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    };
    const response = await axiosApiInstance.post(getDataMetrics, data);
    if (response.status !== 200) {
      return {
        gauge: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const gauge: DataGauge = {
        gaugeData: response.data,
      };

      return {
        gauge,
        error: "",
      };
    }
  } catch (error) {
    return {
      gauge: null,
      error: `Error en la solicitud. ${error}`,
    };
  }
};

const getFunnelDataAPI = async (
  params: FilteredRequest
): Promise<{ funnel: FunnelMetricData | null; error: string }> => {
  try {
    const data = {
      parameters: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    };

    const response = await axiosApiInstance.post(getDataFunnel, data);
    if (response.status !== 200) {
      return {
        funnel: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const funnel: FunnelMetricData = {
        data: response.data.data.map(
          (metric: {
            id: string;
            name: string;
            value: number;
            percent: number;
          }): FunnelMetric => ({
            id: metric.id,
            name: metric.name,
            value: metric.value,
            percent: metric.percent,
          })
        ),
      };
      return {
        funnel,
        error: "",
      };
    }
  } catch (error) {
    return {
      funnel: null,
      error: `Error en la solicitud. ${error}`,
    };
  }
};

const getConvertibilityAPI = async (
  params: FilteredRequest
): Promise<{ convertibility: ConvertibilityData | null; error: string }> => {
  try {
    const data = {
      parameters: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    };

    const response = await axiosApiInstance.post(getDataConvertibility, data);

    if (response.status !== 200) {
      return {
        convertibility: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const convertibility: ConvertibilityData = {
        convertibilityData: response.data.data,
      };
      return {
        convertibility,
        error: "",
      };
    }
  } catch (error) {
    return {
      convertibility: null,
      error: `Error en la solicitud. ${error}`,
    };
  }
};

const getFlowsDataHyperFlowAPI = async (
  params: FilteredRequest
): Promise<{ data: flowsHyperFlowResponse | null; error: string }> => {
  try {
    const data = {
      parameters: {
        startDate: params.startDate,
        endDate: params.endDate,
        flowId: null,
      },
    };

    const response = await axiosApiInstance.post(getDataFunnelHF, data);

    if (response.status !== 200) {
      return {
        data: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const data: flowsHyperFlowResponse = {
        data: response.data.flows,
      };

      return {
        data,
        error: "",
      };
    }
  } catch (error) {
    return {
      data: null,
      error: `Error en la solicitud. ${error}`,
    };
  }
};

const getFunnelHyperFlowByIdAPI = async (
  params: FilteredRequest,
  id: string | undefined
): Promise<{ dataFunnel: funnelResponseHF | null; error: string }> => {
  try {
    const data = {
      parameters: {
        startDate: params.startDate,
        endDate: params.endDate,
        flowId: id,
      },
    };
    const response = await axiosApiInstance.post(getDataFunnelHF, data);

    if (response.status !== 200) {
      return {
        dataFunnel: null,
        error: `Error en la solicitud. Código: ${response.statusText}`,
      };
    } else {
      const dataFunnel: funnelResponseHF = {
        dataFunnel: response.data,
      };

      return {
        dataFunnel,
        error: "",
      };
    }
  } catch (error) {
    return {
      dataFunnel: null,
      error: `Error en la solicitud. ${error}`,
    };
  }
};

const getFilterOptionsFlowAPI = async (): Promise<{
  filterOptions: FlowFilteredOptions | null;
  error: string;
}> => {
  try {
    let filterOptionsResponse = await axiosApiInstance.get(
      `/${getFilterOptions}`
    );
    return {
      filterOptions: filterOptionsResponse.data,
      error: "",
    };
  } catch (err: any) {
    return {
      error: err && err.message,
      filterOptions: null,
    };
  }
};

const getDataPieAdvisorAPI = async (
  params: FilteredRequest
): Promise<{
  dataAdvisor: DataGeneralAdvisor | null;
  error: string;
}> => {
  try {
    const data = {
      parameters: {
        ...params,
      },
    };

    const resp = await axiosApiInstance.post(getDataAdvisorGeneralPie, data);

    if (resp.status !== 200) {
      return {
        dataAdvisor: null,
        error: `Error en la solicitud. Código: ${resp.statusText}`,
      };
    } else {
      let dataAdvisor: DataGeneralAdvisor;

      if ("flowId" in resp.data || "flowStatus" in resp.data) {
        dataAdvisor = resp.data as FlowAdvisorData;
      } else {
        dataAdvisor = { data: resp.data } as GeneralAdvisorData;
      }

      return {
        dataAdvisor,
        error: "",
      };
    }
  } catch (err: any) {
    return {
      dataAdvisor: null,
      error: err && err.message,
    };
  }
};

const getDataStackBarAdvisorAPI = async (
  params: FilteredRequest
): Promise<{
  dataAdvisorStackBar: GeneralAdvisorData | null;
  error: string;
}> => {
  try {
    const data = {
      parameters: {
        ...params,
      },
    };

    const resp = await axiosApiInstance.post(getDataAdvisorStackBar, data);

    if (resp.status !== 200) {
      return {
        dataAdvisorStackBar: null,
        error: `Error en la solicitud. Código: ${resp.statusText}`,
      };
    } else {
      const dataAdvisorStackBar: GeneralAdvisorData = {
        data: resp.data,
      };

      return {
        dataAdvisorStackBar,
        error: "",
      };
    }
  } catch (err: any) {
    return {
      dataAdvisorStackBar: null,
      error: err && err.message,
    };
  }
};

export interface FlowFilteredOptions {
  hyperFlows: { [key: string]: string };
  stepFilters: { [key: string]: string };
}
export interface Gauge {
  title: string;
  score: number;
  difference: number;
  success_users: number;
  difference_success_users: number;
}

export interface DataGauge {
  gaugeData: Array<Gauge>;
}

export interface Statistics {
  title: string;
  score: number;
  difference: number;
}

export interface DataStatistics {
  statisticsData: Array<Statistics>;
}

export interface Pie {
  review?: number;
  approved?: number;
  reject?: number;
  inprogress?: number;
  initialnodata?: number;
  notopencamara?: number;
  datanotfound?: number;
  title: string;
}

export interface DataPie {
  data: Pie[] | [];
}

export interface FilteredRequest {
  page: number;
  startDate: string | null;
  endDate: string | null;
  flowId: string | null;
  asesorId: string | null;
  flowState: string | null;
}

export interface Funnel {
  documentFrontCapture: number;
  documentBackCapture: number;
  documentFilters: number;
  documentValidity: number;
  liveness: number;
  facematch: number;
  otp: number;
  email: number;
  govEntity: number;
  documentVeracity: number;
  initialDataMatch: number;
  lists: number;
  percentDocumentBackCapture: number;
  percentDocumentFilters: number;
  percentDocumentValidity: number;
  percentLiveness: number;
  percentFacematch: number;
  percentOtp: number;
  percentEmail: number;
  percentGovEntity: number;
  percentDocumentVeracity: number;
  percentInitialDataMatch: number;
  percentLists: number;
}

export interface FunnelMetric {
  id: string;
  name: string;
  value: number;
  percent: number;
}

export interface FunnelMetricData {
  data: FunnelMetric[];
}

export interface funnelResponse {
  _id: string;
  name: string;
}

export interface flowsHyperFlowResponse {
  data: Array<funnelResponse>;
}

export interface funnelResponseHF {
  dataFunnel: FunnelHyperFlow[];
}
export interface FunnelHyperFlow {
  data: {
    currentStepStats: {
      [key: string]: number;
    };
    individualStats: any[];
    orderedSteps: string[];
    statsByAcceptanceStatus: Record<string, any>;
    statsByFlowStatus: Record<string, any>;
  };
  flows: string[];
  idFlow: string;
}

export interface convertibility {
  previous_rate: number;
  current_rate: number;
}

export interface ConvertibilityData {
  convertibilityData: convertibility;
}
export interface FunnelData {
  funnelData: Funnel;
}

export interface TitleColumns {
  field: string;
  name: string;
  active: boolean;
}

export interface ColumnsResponse {
  columns: Array<TitleColumns>;
}

export interface Report {
  _id: number;
  startDatetime: string;
  email: string;
  name: string;
  lastName: string;
  phone: string;
  status_core_id: string;
  userId: string;
  currentAssignees: Array<string>;
  AcceptanceStatus: string;
  valorPago: string;
  step: string;
  consultaCentrales: string;
  documentNumber: string;
  deviceId: number;
  gps: number;
  validity: number;
  screen: number;
  faceMatch: number;
  impression: number;
  liveness: number;
  govEntity: number;
  veracity: number;
  lists: number;
  initialDataMatch: number;
  userDataConfirmation: string;
  finalScore: number;
  status: string;
  currentStepId: string;
  flowStatus: string;
  acceptanceStatus: string;
}

export interface ReportsFiltered {
  reportData: Array<Report>;
  numPages: number;
  currentPage: number;
}

export interface GeneralAdvisorData {
  data: Array<DetailDataAdviser>;
}

export interface DetailDataAdviser {
  _id: {
    asesorId: string;
    year: number;
    month: number;
    day: number;
  };
  count: number;
}

export interface FlowAdvisorData {
  flowId: {
    _id: {
      asesorId: string;
      hyperFlowId: string;
    };
    count: number;
  }[];
  flowStatus: {
    _id: {
      asesorId: string;
      flowStatus: string;
      acceptanceStatus: string;
    };
    count: number;
  }[];
}

export type DataGeneralAdvisor = GeneralAdvisorData | FlowAdvisorData;

export {
  getFilteredReportApi,
  downloadReportAPI,
  getStatisticsDataAPI,
  getPieDataAPI,
  getGaugeDataAPI,
  getFunnelDataAPI,
  getConvertibilityAPI,
  getFlowsDataHyperFlowAPI,
  getFunnelHyperFlowByIdAPI,
  getFilterOptionsFlowAPI,
  getDataPieAdvisorAPI,
  getDataStackBarAdvisorAPI,
};
