import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { monthNames } from "../../utils/utils";
import { capitalize } from "../../../../../helpers/utils";

const EchartsStackArea: React.FC<{ dataAdvisorsStackBar: { data: any[] } }> = ({
  dataAdvisorsStackBar,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current, "dark");
      const { data } = dataAdvisorsStackBar;

      const uniqueAsesorIds = Array.from(
        new Set(data.map((advisor) => capitalize(advisor._id.asesorId)))
      );
      const uniqueDates = Array.from(
        new Set(
          data.map(
            (advisor) =>
              `${advisor._id.year} ${new Date(
                0,
                advisor._id.month - 1
              ).toLocaleString("es-ES", { month: "long" })} ${
                advisor._id.day ?? ""
              }`
          )
        )
      ).sort((a, b) => {
        const [yearA, monthA] = a.split(" ");
        const [yearB, monthB] = b.split(" ");

        const yearNumA = parseInt(yearA, 10);
        const yearNumB = parseInt(yearB, 10);

        const monthIndexA = monthNames.indexOf(monthA);
        const monthIndexB = monthNames.indexOf(monthB);

        return yearNumA - yearNumB || monthIndexA - monthIndexB;
      });

      const rawData = uniqueAsesorIds.map((asesorId) =>
        uniqueDates.map((date) => {
          const found = data.find(
            (advisor) =>
              capitalize(advisor._id.asesorId) === asesorId &&
              `${advisor._id.year} ${new Date(
                0,
                advisor._id.month - 1
              ).toLocaleString("es-ES", { month: "long" })} ${
                advisor._id.day ?? ""
              }` === date
          );
          return found?.count ?? 0;
        })
      );
      const colors = uniqueAsesorIds.map(() =>
        echarts.color.modifyHSL(echarts.color.random(), Math.random() * 360)
      );

      const series = uniqueAsesorIds.map((name, index) => ({
        name,
        type: "line",
        stack: "Total",
        areaStyle: {},
        emphasis: { focus: "series" },
        itemStyle: {
          //color: colors[index]
        },
        data: rawData[index],
      }));

      const option = {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        title: { text: "", textStyle: { color: "#FFF" } },
        tooltip: { trigger: "axis", axisPointer: { type: "cross" } },
        legend: {
          textStyle: { color: "#FFF" },
          data: uniqueAsesorIds,
          type: "scroll",
          pageIconColor: "#FFF",
          pageTextStyle: { color: "#FFF" },
        },
        grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: uniqueDates,
          axisLabel: { color: "#FFF" },
        },
        yAxis: { type: "value", axisLabel: { color: "#FFF" } },
        series,
      };

      myChart.setOption(option);
      window.addEventListener("resize", () => myChart.resize());
      return () => myChart.dispose();
    }
  }, [dataAdvisorsStackBar]);

  return <div ref={chartRef} style={{ width: "100%", height: "450px" }} />;
};

export default EchartsStackArea;
