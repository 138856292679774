import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeActiveComponent,
  changeCurrentFilters,
  dataGeneralIndicator,
  getDataClients,
  selectBillingComponent,
  selectDataClients,
  selectDataIndicators,
  selectFilters,
  selectLoading,
  selectNameClient,
  selectVisualization,
} from "../BillingSlice";
import {
  GeneralIndicatorsCard,
  TopComponent,
  EchartsGraphStackBar,
  EchartsGraphStackArea,
  TableComponent,
} from "./components";
import { convertDateStandard } from "../../../../helpers/utils";
import { ReactComponent as BarIcon } from "../../../../Assets/common/images/barGraphIcon.svg";
import { ReactComponent as AreaIcon } from "../../../../Assets/common/images/stackArea.svg";
import { ReactComponent as Warning } from "../../../../Assets/common/images/warningGreen.svg";
import { RadioButton } from "../../../common/components/Btns/RadioButton";
import { generateDetailReport } from "../BillingSlice";
import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from "react-loading-overlay-ts";
import { AlertPopUp } from "./components/AlertPopUp";

export const Analytics = () => {
  const dispatch = useAppDispatch();
  const generalIndicators = useAppSelector(selectDataIndicators);
  const datesFilters = useAppSelector(selectFilters);
  const selectedVisualization = useAppSelector(selectVisualization);
  const nameClient = useAppSelector(selectNameClient);
  const activeComponent = useAppSelector(selectBillingComponent);
  const dataClients = useAppSelector(selectDataClients);
  const loading = useAppSelector(selectLoading);
  const { startDate, endDate } = datesFilters;
  const [changeGraph, setChangeGraph] = useState<"stackBar" | "stackArea">(
    "stackBar"
  );
  const [showModal, setShowModal] = useState(false);

  const {
    total,
    percentChange,
    clientDateCount,
    serviceDateCount,
    clientCount,
    serviceCount,
  } = generalIndicators || {};

  useEffect(() => {
    if (!dataClients) {
      dispatch(getDataClients());
    }
    if (datesFilters) {
      dispatch(dataGeneralIndicator({ dataFiltered: datesFilters }));
    }
    return () => {};
  }, [datesFilters]);

  useEffect(() => {
    if (activeComponent !== "Analytics") {
      dispatch(changeActiveComponent("Analytics"));
    }
  }, [activeComponent]);

  const { diffTimeNew } = convertDateStandard(startDate, endDate) || {};

  return (
    <LoadingOverlay
      active={loading === "pending"}
      spinner={<ClipLoader color="white" size="150px" />}
    >
      <AlertPopUp
        title={"¡RECUERDA!"}
        mensaje={
          "Para poder generar el reporte debe seleccionar un rango de fecha"
        }
        showModal={showModal}
        onClose={() => setShowModal(false)}
        items={<Warning />}
      />
      <div className="h-100 p-3 overflow-auto">
        <div className="flex-col conteiner-indicators-card">
          <div className="conteiner-indicators-dates">
            <p>Indicadores</p>
          </div>
          <div className="conteiner-indicators">
            <GeneralIndicatorsCard
              title="Valor a recaudar"
              value={total ?? 0}
            />
            <GeneralIndicatorsCard
              title="Cambio Porcentual respecto a rango anterior"
              value={percentChange ?? 0}
              colorRules={{
                positive: "#66FF66",
                negative: "#FF8A37",
                zero: "#ffff",
              }}
              isPercentage
            />
            {selectedVisualization === "Clientes" && (
              <TopComponent
                dataClients={clientCount ?? {}}
                title="Top de Clientes"
              />
            )}
            {(selectedVisualization === "Servicios" ||
              selectedVisualization === "Clienteyservicio") && (
              <TopComponent
                dataClients={serviceCount ?? {}}
                title="Top de Servicios"
              />
            )}
          </div>
        </div>
        <div className="conteiner-indicators-card mt-3">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <button
                className="button-style"
                style={{
                  backgroundColor:
                    changeGraph === "stackBar"
                      ? "rgba(0,0,0,0.2)"
                      : "rgba(0,0,0,0.0)",
                }}
                onClick={() => {
                  setChangeGraph("stackBar");
                }}
              >
                <BarIcon />{" "}
              </button>
              <button
                className="button-style"
                style={{
                  backgroundColor:
                    changeGraph === "stackArea"
                      ? "rgba(0,0,0,0.2)"
                      : "rgba(0,0,0,0.0)",
                }}
                onClick={() => {
                  setChangeGraph("stackArea");
                }}
              >
                <AreaIcon />
              </button>
            </div>
            {/* <div>
              <RadioButton
                label="Mostrar acumulado"
                checked={datesFilters.accumulated === true}
                onClick={() => {
                  if (datesFilters.accumulated === true) {
                    dispatch(
                      changeCurrentFilters({
                        ...datesFilters,
                        accumulated: false,
                      })
                    );
                  }
                }}
                onChange={() => {
                  dispatch(
                    changeCurrentFilters({ ...datesFilters, accumulated: true })
                  );
                }}
              />
            </div> */}
          </div>
          {changeGraph === "stackBar" && (
            <>
              {selectedVisualization === "Clientes" && (
                <div
                  style={{
                    backgroundColor: "rgb(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <EchartsGraphStackBar
                    startDate={startDate ? new Date(startDate) : new Date()}
                    endDate={endDate ? new Date(endDate) : new Date()}
                    clientDateCount={clientDateCount || {}}
                    rangeInDays={diffTimeNew ?? 0}
                    title="Clientes"
                  />
                </div>
              )}

              {(selectedVisualization === "Servicios" ||
                selectedVisualization === "Clienteyservicio") && (
                <div
                  style={{
                    backgroundColor: "rgb(0,0,0,0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <EchartsGraphStackBar
                    startDate={startDate ? new Date(startDate) : new Date()}
                    endDate={endDate ? new Date(endDate) : new Date()}
                    clientDateCount={serviceDateCount || {}}
                    rangeInDays={diffTimeNew ?? 0}
                    title={
                      selectedVisualization === "Clienteyservicio"
                        ? nameClient
                        : "Servicios"
                    }
                  />
                </div>
              )}
            </>
          )}

          {changeGraph === "stackArea" &&
            selectedVisualization === "Clientes" && (
              <div
                style={{
                  backgroundColor: "rgb(0,0,0,0.2)",
                  borderRadius: "8px",
                }}
              >
                <EchartsGraphStackArea
                  startDate={startDate ? new Date(startDate) : new Date()}
                  endDate={endDate ? new Date(endDate) : new Date()}
                  clientDateCount={clientDateCount || {}}
                  rangeInDays={diffTimeNew ?? 0}
                  title="Clientes"
                />
              </div>
            )}
          {changeGraph === "stackArea" &&
            (selectedVisualization === "Servicios" ||
              selectedVisualization === "Clienteyservicio") && (
              <div
                style={{
                  backgroundColor: "rgb(0,0,0,0.2)",
                  borderRadius: "8px",
                }}
              >
                <EchartsGraphStackArea
                  startDate={startDate ? new Date(startDate) : new Date()}
                  endDate={endDate ? new Date(endDate) : new Date()}
                  clientDateCount={serviceDateCount || {}}
                  rangeInDays={diffTimeNew ?? 0}
                  title={
                    selectedVisualization === "Clienteyservicio"
                      ? nameClient
                      : "Servicios"
                  }
                />
              </div>
            )}
        </div>
        <div className="mt-3 conteiner-indicators-card p-2 overflow-auto">
          {selectedVisualization === "Clientes" && (
            <TableComponent
              data={clientDateCount || {}}
              title="Cliente"
              downloadReport={() => {
                if (startDate && endDate) {
                  dispatch(
                    generateDetailReport({
                      dataFiltered: {
                        ...datesFilters,
                        visualizationType: "CLIENTS",
                      },
                    })
                  );

                  dispatch(
                    changeCurrentFilters({
                      ...datesFilters,
                      visualizationType: "CLIENTS",
                    })
                  );
                } else {
                  setShowModal(true);
                }
              }}
              rangeInDays={diffTimeNew ?? 0}
            />
          )}

          {(selectedVisualization === "Servicios" ||
            selectedVisualization === "Clienteyservicio") && (
            <TableComponent
              data={serviceDateCount || {}}
              title="Servicio"
              downloadReport={() => {
                if (startDate && endDate) {
                  dispatch(
                    generateDetailReport({
                      dataFiltered: {
                        ...datesFilters,
                        visualizationType: "SERVICE",
                      },
                    })
                  );

                  dispatch(
                    changeCurrentFilters({
                      ...datesFilters,
                      visualizationType: "SERVICE",
                    })
                  );
                } else {
                  setShowModal(true);
                }
              }}
              rangeInDays={diffTimeNew ?? 0}
            />
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};
