import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import { capitalize } from "../../../../../helpers/utils";

interface EchartsGraphPieProps {
  data: { [key: string]: number };
  showPercentege?: boolean;
  showName?: boolean;
  showList?: boolean;
  title?: string;
}

export const EchartsPie: React.FC<EchartsGraphPieProps> = ({
  data,
  showPercentege,
  showName,
  showList,
  title,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const myChartRef = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      myChartRef.current = echarts.init(chartRef.current);
    }

    const colorMapping: { [key: string]: string } = {
      Completado: "#4CAF50",
      Aprobado: "#A1EBA5",
      Rechazado: "#EB5F6A",
      "Por revisar": "#DBA659",
      "En proceso": "#fac858",
      Desistido: "#FF7043",
      "En espera a usuario": "#0778f0",
    };

    const chartData = Object.entries(data).map(([name, value]) => ({
      name: capitalize(name),
      value,
      itemStyle: {
        color: colorMapping[name],
      },
    }));

    const totalValue = chartData.reduce((sum, item) => sum + item.value, 0);

    const option: echarts.EChartsOption = {
      backgroundColor: "rgba(0, 0, 0, 0.0)",
      title: {
        text: showList ? `Lista ${title}` : "",
        left: 50,
        textStyle: {
          color: "#ffffff",
          fontSize: 12,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c} ({d}%)",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderColor: "#2980b9",
        borderWidth: 1,
        textStyle: {
          color: "#ecf0f1",
          fontSize: 14,
        },
      },
      legend: {
        show: showList ? true : false,
        orient: "vertical",
        top: 20,
        left: 10,
        textStyle: {
          color: "#ffffff",
          fontSize: 12,
        },
        type: "scroll",
      },
      series: [
        {
          name: title,
          type: "pie",
          radius: ["35%", "65%"],
          center: ["50%", "50%"],
          left: showList ? 200 : 60,
          data: chartData,
          itemStyle: {
            borderRadius: 8,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 15,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.4)",
            },
          },
          label: {
            formatter: (params) => {
              const percentage = (
                (Number(params.value) / totalValue) *
                100
              ).toFixed(2);
              return showPercentege
                ? `${
                    showName ? `${capitalize(params.name)}:` : ""
                  } ${percentage}%`
                : `${showName ? `${capitalize(params.name)}:` : ""} ${
                    params.value
                  }`;
            },
            color: "#ffffff",
            fontSize: 13,
          },
          labelLine: {
            length: 20,
            length2: 10,
            lineStyle: {
              color: "#ffffff",
              width: 1,
            },
          },
        },
      ],
    };

    myChartRef.current?.setOption(option);

    const handleResize = () => {
      myChartRef.current?.resize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      myChartRef.current?.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [data, showPercentege]);

  return <div ref={chartRef} style={{ width: "100%", height: "60vh" }} />;
};
