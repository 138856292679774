import { convertDateStandard } from "../../../../helpers/utils";
import { ConstraintType, Service, StartBilling } from "../BillingAPI";
type TransformableData = Record<string, any>;
type NameMap = Record<string, string>;

export const transformKeysWithMap = (
  data: TransformableData,
  nameMap: NameMap
): TransformableData => {
  const transformedData: TransformableData = {};

  Object.entries(data).forEach(([key, value]) => {
    const transformedKey = nameMap[key] || key;

    transformedData[transformedKey] = value;
  });
  return transformedData;
};

export const mapAllServices = (
  services: Record<string, Service>,
  stepNameMap: Record<ConstraintType, string>,
  billingNameMap: Record<StartBilling, string>
): Record<string, Service> => {
  return Object.fromEntries(
    Object.entries(services).map(([key, service]) => [
      key,
      mapServiceValues(service, stepNameMap, billingNameMap),
    ])
  );
};

export const mapServiceValues = (
  service: Service,
  stepNameMap: Record<ConstraintType, string>,
  billingNameMap: Record<StartBilling, string>
): Service => {
  return {
    ...service,
    startBilling:
      (billingNameMap[service.startBilling] as StartBilling) ||
      service.startBilling,
    constraints: service.constraints.map((constraint) => ({
      ...constraint,
      constraintType:
        (stepNameMap[constraint.constraintType] as ConstraintType) ||
        constraint.constraintType,
    })),
  };
};

export const serviceIdToNameMap = {
  COREID: "CoreId",
  FORMS: "Formularios",
  FIRMA: "Firmas electrónicas",
  FIRMA_BASIC: "Firmas Basicas",
  BLACKLIST: "Consulta manual en listas",
  EXPERIAN: "Consultas en Experian",
  PAGARE: "Pagarés",
};

export const serviceIdToName = (serviceId: string | null) => {
  if (serviceId) {
    switch (serviceId) {
      case "COREID":
        return serviceIdToNameMap.COREID;
      case "FORMS":
        return serviceIdToNameMap.FORMS;
      case "FIRMA":
        return serviceIdToNameMap.FIRMA;
      case "FIRMA_BASIC":
        return serviceIdToNameMap.FIRMA_BASIC;
      case "BLACKLIST":
        return serviceIdToNameMap.BLACKLIST;
      case "EXPERIAN":
        return serviceIdToNameMap.EXPERIAN;
      case "PAGARE":
        return serviceIdToNameMap.PAGARE;
      default:
        return serviceId;
    }
  } else {
    return "";
  }
};

export const hyperFlowStepNameMap: Record<ConstraintType, string> = {
  HYPERFLOW_STEP_UNBILLABLE: "Paso No Facturable",
  HYPERFLOW_STEP_BILLABLE: "Paso Facturable",
};

export const startBillingNameMap: Record<StartBilling, string> = {
  FINISHED: "Al finalizarse",
  FIRST_CAPTURED: "Primera Captura",
  PARTIALLY_SIGNED: "Firmado parcialmente",
};

export const formatCurrency = (value: number) => {
  if (value === -1) {
    return "Meta no establecida";
  }
  return value.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
};

export const getFormattedDates = (
  allDates: string[],
  rangeInDays: number
): string[] => {
  const isMoreThan60Days = rangeInDays > 60;

  return allDates.map((dateStr) => {
    const converted = convertDateStandard(dateStr);

    if (!converted) {
      return "Fecha inválida";
    }

    const {
      formatMonthDate,
      formatUTCStartDate,
      monthUTCName,
      formatYearDate,
    } = converted;

    if (isMoreThan60Days) {
      return `${monthUTCName} ${formatYearDate}`;
    } else if (rangeInDays > 30) {
      return `${formatUTCStartDate}/${formatMonthDate}`;
    } else {
      return `${formatUTCStartDate} ${monthUTCName}`;
    }
  });
};
