import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { LoadingStatus } from "../../common/commonSlice";
import {
  ReportsFiltered,
  ColumnsResponse,
  getFilteredReportApi,
  downloadReportAPI,
  DataStatistics,
  getStatisticsDataAPI,
  getPieDataAPI,
  getGaugeDataAPI,
  DataPie,
  DataGauge,
  flowsHyperFlowResponse,
  FunnelHyperFlow,
  getFunnelDataAPI,
  getConvertibilityAPI,
  ConvertibilityData,
  getFlowsDataHyperFlowAPI,
  getFunnelHyperFlowByIdAPI,
  FunnelMetricData,
  FlowFilteredOptions,
  getDataPieAdvisorAPI,
  DataGeneralAdvisor,
  getDataStackBarAdvisorAPI,
  GeneralAdvisorData,
} from "./DashboardApi";
import { BackofficeAsesor, getAsesoresAPI } from "../analytics/analyticsAPI";
import { getFilterOptionsFlowAPI } from "./DashboardApi";
import { transformDataWithAdvisors, transformResponse } from "./utils/utils";
import { statusIdToNameMap } from "../../../helpers/utils";

export type DashboardActiveComponent =
  | "Reports"
  | "Indicators"
  | "ConvertibilityFunnel"
  | "Advisors"
  | "none";

export const getFilteredReport = createAsyncThunk(
  "dashboard/getFilteredReport",
  async (params: { filter: FilteredDashboardRequest }, { rejectWithValue }) => {
    try {
      const filteredReport = await getFilteredReportApi(params.filter);
      return filteredReport;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadReport = createAsyncThunk(
  "dashboard/downloadReport",
  async (
    params: { idClient: string; filter: FilteredDashboardRequest },
    { rejectWithValue }
  ) => {
    try {
      const statusDownload = await downloadReportAPI(
        params.idClient,
        params.filter
      );
      return statusDownload;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getStatisticsData = createAsyncThunk(
  "dashboard/getStatisticsData",
  async (params: { filter: FilteredDashboardRequest }, { rejectWithValue }) => {
    try {
      const statisticsData = await getStatisticsDataAPI(params.filter);
      return statisticsData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getPieData = createAsyncThunk(
  "dashboard/getPieData",
  async (params: { filter: FilteredDashboardRequest }, { rejectWithValue }) => {
    try {
      const pieData = await getPieDataAPI(params.filter);
      return pieData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getGaugeData = createAsyncThunk(
  "dashboard/getGaugeData",
  async (params: { filter: FilteredDashboardRequest }, { rejectWithValue }) => {
    try {
      const gaugeData = await getGaugeDataAPI(params.filter);
      return gaugeData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFunnelData = createAsyncThunk(
  "dashboard/getFunnelData",
  async (params: { filter: FilteredDashboardRequest }, { rejectWithValue }) => {
    try {
      const funnelData = await getFunnelDataAPI(params.filter);
      return funnelData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getConvertibilityData = createAsyncThunk(
  "dashboard/getConvertibilityData",
  async (params: { filter: FilteredDashboardRequest }, { rejectWithValue }) => {
    try {
      const convertibilityData = await getConvertibilityAPI(params.filter);

      return convertibilityData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFlowDataHF = createAsyncThunk(
  "dashboard/getFlowDataHF",
  async (params: { filter: FilteredDashboardRequest }, { rejectWithValue }) => {
    try {
      const dataFlowId = await getFlowsDataHyperFlowAPI(params.filter);

      return dataFlowId;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getFunnelHyperFlowById = createAsyncThunk(
  "dashboard/getFunnelByID",
  async (
    params: { filter: FilteredDashboardRequest; id: string | undefined },
    { rejectWithValue }
  ) => {
    try {
      const response = await getFunnelHyperFlowByIdAPI(
        params.filter,
        params.id
      );

      return response.dataFunnel;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getAsesores = createAsyncThunk(
  "dashboard/analytics/getAsesores",
  async (_, {}) => {
    const asesores = await getAsesoresAPI();

    if (asesores) {
      const clientIdNameMap: Record<string, string> = {};
      asesores.map((asesor) => {
        clientIdNameMap[asesor.id] = asesor.name;
      });
      return {
        asesores,
        clientIdNameMap,
      };
    }
    return asesores;
  }
);

export const getFilterOptions = createAsyncThunk(
  "dashboard/analytics/hyperflow/filterOptions",
  async (_, { rejectWithValue }) => {
    const filterOptions = await getFilterOptionsFlowAPI();

    if (filterOptions.error && filterOptions.error !== "") {
      return rejectWithValue(filterOptions.error);
    } else {
      return filterOptions;
    }
  }
);

export const getGeneralAdvisors = createAsyncThunk(
  "dashboard/analytics/generalIndicatorsAdvisors",
  async (
    params: { filter: FilteredDashboardRequest },
    { rejectWithValue, getState }
  ) => {
    try {
      const { dataAdvisor } = await getDataPieAdvisorAPI(params.filter);
      const { dataAdvisorStackBar } = await getDataStackBarAdvisorAPI(
        params.filter
      );
      const { dashboard } = getState() as RootState;
      const { advisors, listFlows } = dashboard;

      if (!dataAdvisor) {
        return rejectWithValue("No data received from API");
      }

      let processedDataAdvisor;
      if ("flowId" in dataAdvisor) {
        const transformed = transformResponse(
          dataAdvisor,
          advisors,
          listFlows?.hyperFlows,
          statusIdToNameMap
        );

        processedDataAdvisor = {
          flowId: transformed.flowId ?? [],
          flowStatus: transformed.flowStatus ?? [],
        };
      } else {
        processedDataAdvisor = {
          data: transformDataWithAdvisors(dataAdvisor.data, advisors),
        };
      }

      return {
        dataAdvisorStackBar: {
          data: transformDataWithAdvisors(dataAdvisorStackBar?.data, advisors),
        },
        dataAdvisor: processedDataAdvisor,
      };
    } catch (err: any) {
      return rejectWithValue(err.message || "Error desconocido");
    }
  }
);

export interface DashboardState {
  activeComponent: DashboardActiveComponent;
  loadingReportData: LoadingStatus;
  loadingStatisticsData: LoadingStatus;
  loadingPieData: LoadingStatus;
  loadingGaugeData: LoadingStatus;
  loadingFunnelData: LoadingStatus;
  loadingConvertibility: LoadingStatus;
  loadingListflows: LoadingStatus;
  loadingGeneralAdvisors: LoadingStatus;
  downloadReport: LoadingStatus;
  reportsFiltered: ReportsFiltered | null;
  dataStatistics: DataStatistics | null;
  dataGauge: DataGauge | null;
  dataPie: DataPie | null;
  dataFunnelCoreId: FunnelMetricData | null;
  dataFunnelHyperFlow: FunnelHyperFlow[];
  dataFlowIdHF: flowsHyperFlowResponse | null;
  dataConvertibility: ConvertibilityData | null;
  columnsResponse: ColumnsResponse | null;
  currentFilteredRequest: FilteredDashboardRequest;
  currentPage: number;
  numPages: number;
  advisorsInfoComplete: BackofficeAsesor[] | null;
  advisors: Record<string, string> | null;
  nameAdvisor: string | null;
  listFlows: FlowFilteredOptions | null;
  dataPieAdvisors: DataGeneralAdvisor | null;
  dataAdvisorsStackBar: GeneralAdvisorData | null;
}

const initialState: DashboardState = {
  activeComponent: "none",
  loadingReportData: "idle",
  loadingStatisticsData: "idle",
  loadingPieData: "idle",
  loadingGaugeData: "idle",
  loadingFunnelData: "idle",
  loadingConvertibility: "idle",
  loadingListflows: "idle",
  loadingGeneralAdvisors: "idle",
  downloadReport: "idle",
  currentPage: 0,
  numPages: 0,
  reportsFiltered: null,
  columnsResponse: null,
  dataStatistics: null,
  dataGauge: null,
  dataPie: null,
  dataFunnelCoreId: null,
  dataFunnelHyperFlow: [],
  dataFlowIdHF: null,
  dataConvertibility: null,
  currentFilteredRequest: {
    endDate: null,
    page: 0,
    startDate: null,
    flowId: null,
    asesorId: null,
    flowState: null,
  },
  advisorsInfoComplete: null,
  advisors: null,
  nameAdvisor: null,
  listFlows: null,
  dataPieAdvisors: null,
  dataAdvisorsStackBar: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    changeActiveComponent: (
      state,
      action: PayloadAction<DashboardActiveComponent>
    ) => {
      state.activeComponent = action.payload;
      state.currentPage = 0;
      state.numPages = 0;
    },

    changeCurrentFilters: (
      state,
      action: PayloadAction<FilteredDashboardRequest>
    ) => {
      state.currentFilteredRequest = { ...action.payload, page: 0 };
    },

    changePageState: (state, action: PayloadAction<number>) => {
      let current = state.currentFilteredRequest;
      state.currentFilteredRequest = { ...current, page: action.payload };
    },
    clearFlowsHyperFlowData: (state) => {
      state.dataFlowIdHF = null;
    },
    clearFunnelHyperFlowData: (state) => {
      state.dataFunnelHyperFlow = [];
    },
    setNameAdvisor: (state, action: PayloadAction<string>) => {
      state.nameAdvisor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredReport.fulfilled, (state, action) => {
        let reportData = action.payload;
        if (reportData.reportsData) {
          state.reportsFiltered = reportData.reportsData;
          state.numPages = reportData.reportsData.numPages;
          state.currentPage = reportData.reportsData.currentPage;
          state.columnsResponse = reportData.titles;
          state.loadingReportData = "resolved";
        } else {
          state.loadingReportData = "rejected";
        }
      })
      .addCase(getFilteredReport.rejected, (state) => {
        state.loadingReportData = "rejected";
      })
      .addCase(getFilteredReport.pending, (state) => {
        state.loadingReportData = "pending";
      });

    builder
      .addCase(downloadReport.fulfilled, (state, action) => {
        let download = action.payload;
        if (download) {
          state.downloadReport = "resolved";
        } else {
          state.downloadReport = "rejected";
        }
      })
      .addCase(downloadReport.rejected, (state) => {
        state.downloadReport = "rejected";
      })
      .addCase(downloadReport.pending, (state) => {
        state.downloadReport = "pending";
      });

    builder
      .addCase(getStatisticsData.fulfilled, (state, action) => {
        let statisticsData = action.payload;
        if (statisticsData.statistics) {
          state.dataStatistics = statisticsData.statistics;
          state.loadingStatisticsData = "resolved";
        } else {
          state.loadingStatisticsData = "rejected";
        }
      })
      .addCase(getStatisticsData.rejected, (state) => {
        state.loadingStatisticsData = "rejected";
      })
      .addCase(getStatisticsData.pending, (state) => {
        state.loadingStatisticsData = "pending";
      });

    builder
      .addCase(getPieData.fulfilled, (state, action) => {
        let pieData = action.payload;
        if (pieData.pie) {
          state.dataPie = pieData.pie;
          state.loadingPieData = "resolved";
        } else {
          state.loadingPieData = "rejected";
        }
      })
      .addCase(getPieData.rejected, (state) => {
        state.loadingPieData = "rejected";
      })
      .addCase(getPieData.pending, (state) => {
        state.loadingPieData = "pending";
      });
    builder
      .addCase(getGaugeData.fulfilled, (state, action) => {
        let gaugeData = action.payload;
        if (gaugeData.gauge) {
          state.dataGauge = gaugeData.gauge;
          state.loadingGaugeData = "resolved";
        } else {
          state.loadingGaugeData = "rejected";
        }
      })
      .addCase(getGaugeData.rejected, (state) => {
        state.loadingGaugeData = "rejected";
      })
      .addCase(getGaugeData.pending, (state) => {
        state.loadingGaugeData = "pending";
      });

    builder
      .addCase(getFunnelData.fulfilled, (state, action) => {
        let funnelData = action.payload;
        if (funnelData) {
          state.loadingFunnelData = "resolved";
          state.dataFunnelCoreId = funnelData.funnel;
        } else {
          state.loadingFunnelData = "rejected";
        }
      })
      .addCase(getFunnelData.pending, (state) => {
        state.loadingFunnelData = "pending";
      })
      .addCase(getFunnelData.rejected, (state) => {
        state.loadingFunnelData = "rejected";
      });
    builder
      .addCase(getConvertibilityData.fulfilled, (state, action) => {
        let convertibilityData = action.payload;
        if (convertibilityData) {
          state.loadingConvertibility = "resolved";
          state.dataConvertibility = convertibilityData.convertibility;
        } else {
          state.loadingConvertibility = "rejected";
        }
      })
      .addCase(getConvertibilityData.pending, (state) => {
        state.loadingConvertibility = "pending";
      })
      .addCase(getConvertibilityData.rejected, (state) => {
        state.loadingConvertibility = "rejected";
      });
    builder
      .addCase(getFlowDataHF.fulfilled, (state, action) => {
        let funnelData = action.payload;
        if (funnelData) {
          state.loadingFunnelData = "resolved";
          state.dataFlowIdHF = funnelData.data;
        } else {
          state.loadingFunnelData = "rejected";
        }
      })
      .addCase(getFlowDataHF.pending, (state) => {
        state.loadingFunnelData = "pending";
      })
      .addCase(getFlowDataHF.rejected, (state) => {
        state.loadingFunnelData = "rejected";
      });
    builder
      .addCase(getFunnelHyperFlowById.fulfilled, (state, action) => {
        const funnelDataHyperFlow = action.payload;

        if (funnelDataHyperFlow?.dataFunnel) {
          state.loadingFunnelData = "resolved";

          if (Array.isArray(funnelDataHyperFlow.dataFunnel)) {
            state.dataFunnelHyperFlow = [
              ...state.dataFunnelHyperFlow,
              ...funnelDataHyperFlow.dataFunnel,
            ];
          } else {
            state.dataFunnelHyperFlow.push(funnelDataHyperFlow.dataFunnel);
          }
        } else {
          state.loadingFunnelData = "rejected";
        }
      })
      .addCase(getFunnelHyperFlowById.pending, (state) => {
        state.loadingFunnelData = "pending";
      })
      .addCase(getFunnelHyperFlowById.rejected, (state) => {
        state.loadingFunnelData = "rejected";
      });
    builder.addCase(getAsesores.fulfilled, (state, action) => {
      state.advisorsInfoComplete = action.payload.asesores;
      state.advisors = action.payload.clientIdNameMap;
    });
    builder
      .addCase(getFilterOptions.fulfilled, (state, action) => {
        let { filterOptions } = action.payload;
        if (filterOptions) {
          state.listFlows = filterOptions;
          state.loadingListflows = "resolved";
        } else {
          state.loadingListflows = "rejected";
        }
      })
      .addCase(getFilterOptions.pending, (state) => {
        state.loadingListflows = "pending";
      })
      .addCase(getFilterOptions.rejected, (state) => {
        state.loadingListflows = "rejected";
        state.listFlows = {
          hyperFlows: {},
          stepFilters: {},
        };
      });
    builder
      .addCase(getGeneralAdvisors.fulfilled, (state, action) => {
        if (action.payload) {
          const dataAdvisor = action.payload.dataAdvisor;

          if ("flowId" in dataAdvisor && "flowStatus" in dataAdvisor) {
            state.dataPieAdvisors = dataAdvisor;
          } else if ("data" in dataAdvisor) {
            state.dataPieAdvisors = {
              data: dataAdvisor.data,
            };
          } else {
            state.dataPieAdvisors = null;
          }

          state.dataAdvisorsStackBar = {
            data: action.payload.dataAdvisorStackBar?.data ?? [],
          };
          state.loadingGeneralAdvisors = "resolved";
        } else {
          state.loadingGeneralAdvisors = "resolved";
          state.dataPieAdvisors = null;
        }
      })
      .addCase(getGeneralAdvisors.pending, (state) => {
        state.loadingGeneralAdvisors = "pending";
      })
      .addCase(getGeneralAdvisors.rejected, (state) => {
        state.loadingGeneralAdvisors = "rejected";
      });
  },
});

export interface FilteredDashboardRequest {
  page: number;
  startDate: string | null;
  endDate: string | null;
  flowId: string | null;
  asesorId: string | null;
  flowState: string | null;
}

export const selectCurrentPageReports = (state: RootState) =>
  state.dashboard.currentPage;

export const selectNumPagesReports = (state: RootState) =>
  state.dashboard.numPages;

export const selectActiveDashboardComponent = (state: RootState) =>
  state.dashboard.activeComponent;

export const selectLoginStatusReportsComponent = (state: RootState) =>
  state.dashboard.loadingReportData;

export const selectCurrentFilteredDashboard = (state: RootState) =>
  state.dashboard.currentFilteredRequest;

export const selectFilteredReport = (state: RootState) =>
  state.dashboard.reportsFiltered;

export const selectLoadingReport = (state: RootState) =>
  state.dashboard.downloadReport;

export const selectColumnsReport = (state: RootState) =>
  state.dashboard.columnsResponse;

export const selectLoadingStatisticsData = (state: RootState) =>
  state.dashboard.loadingStatisticsData;

export const selectLoadingPieData = (state: RootState) =>
  state.dashboard.loadingPieData;

export const selectLoadingGaugeData = (state: RootState) =>
  state.dashboard.loadingGaugeData;

export const selectDataStatistics = (state: RootState) =>
  state.dashboard.dataStatistics;

export const selectDataPie = (state: RootState) => state.dashboard.dataPie;

export const selectDataGauge = (state: RootState) => state.dashboard.dataGauge;

export const selectDataFunnel = (state: RootState) =>
  state.dashboard.dataFunnelCoreId;

export const selectLoadingDataFunnel = (state: RootState) =>
  state.dashboard.loadingFunnelData;

export const selectLoadingDataConvertibility = (state: RootState) =>
  state.dashboard.loadingConvertibility;

export const selectDataConvertibility = (state: RootState) =>
  state.dashboard.dataConvertibility;

export const selectDataFlowIdHF = (state: RootState) =>
  state.dashboard.dataFlowIdHF;

export const selectDataFunnelHF = (state: RootState) =>
  state.dashboard.dataFunnelHyperFlow;

export const selectDataAsesores = (state: RootState) =>
  state.dashboard.advisorsInfoComplete;

export const selectDataFlows = (state: RootState) => state.dashboard.listFlows;

export const selectNameAdvisor = (state: RootState) =>
  state.dashboard.nameAdvisor;

export const selectDataIndicatorsAdvisors = (state: RootState) =>
  state.dashboard.dataPieAdvisors;

export const selectDataStackBarAdvisors = (state: RootState) =>
  state.dashboard.dataAdvisorsStackBar;

export const selectLoadingGeneralAdvisors = (state: RootState) =>
  state.dashboard.loadingGeneralAdvisors;

export const {
  changeActiveComponent,
  changeCurrentFilters,
  changePageState,
  clearFunnelHyperFlowData,
  clearFlowsHyperFlowData,
  setNameAdvisor,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
