import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { capitalize } from "../../../../../helpers/utils";
import { monthNames } from "../../utils/utils";

interface AdvisorData {
  _id: {
    asesorId: string;
    year: number;
    month: number;
    day?: number;
  };
  count: number;
}

interface DataAdvisorsStackBar {
  data: AdvisorData[];
}

interface EchartsStackBarProps {
  dataAdvisorsStackBar: DataAdvisorsStackBar;
}

const EchartsStackBar: React.FC<EchartsStackBarProps> = ({
  dataAdvisorsStackBar,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current, "dark");

      const { data } = dataAdvisorsStackBar;

      const uniqueAsesorIds = Array.from(
        new Set(data.map((advisor) => capitalize(advisor._id.asesorId)))
      );

      console.log("asesors", uniqueAsesorIds);

      const uniqueDates = Array.from(
        new Set(
          data.map(
            (advisor) =>
              `${advisor._id.year} ${new Date(
                0,
                advisor._id.month - 1
              ).toLocaleString("es-ES", { month: "long" })} ${
                advisor._id.day ?? ""
              }`
          )
        )
      ).sort((a, b) => {
        const [yearA, monthA] = a.split(" ");
        const [yearB, monthB] = b.split(" ");

        const yearNumA = parseInt(yearA, 10);
        const yearNumB = parseInt(yearB, 10);

        const monthIndexA = monthNames.indexOf(monthA);
        const monthIndexB = monthNames.indexOf(monthB);

        return yearNumA - yearNumB || monthIndexA - monthIndexB;
      });

      console.log(uniqueDates);

      const baseColors = ["#8eabae", "#62dad5", "#5d6bdf"];

      const colors = uniqueAsesorIds.map((_, i) =>
        echarts.color.modifyHSL(baseColors[i % baseColors.length], i * 150)
      );

      const rawData = uniqueAsesorIds.map((asesorId) =>
        uniqueDates.map((date) => {
          if (!data || data.length === 0) {
            return 0;
          }

          const found = data.find(
            (advisor) =>
              capitalize(advisor?._id?.asesorId) === asesorId &&
              `${advisor._id.year} ${new Date(
                0,
                advisor._id.month - 1
              ).toLocaleString("es-ES", { month: "long" })} ${
                advisor._id.day ?? ""
              }` === date
          );

          return found?.count ?? 0;
        })
      );

      if (
        !rawData.length ||
        rawData.every((arr) => arr.every((val) => val === 0))
      ) {
        rawData.push(Array(uniqueDates.length).fill(0));
      }

      console.log("🔹 rawData final:", rawData);

      const totalData: number[] = rawData[0].map((_, i) =>
        rawData.reduce((sum, row) => sum + row[i], 0)
      );

      const series = uniqueAsesorIds.map((name, index) => ({
        name,
        type: "bar",
        stack: "total",
        barWidth: "60%",
        label: {
          show: true,
          color: "#FFF",
          fontSize: 12,
          position: "inside",
          formatter: (params: any) =>
            params.value > 0 ? `${params.value}` : "",
        },
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
        emphasis: {
          focus: "series",
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.8)",
          },
        },
        data: rawData[index].map((value, i) => (totalData[i] <= 0 ? 0 : value)),
      }));

      const option = {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: [5, 5, 5, 5],
        legend: {
          textStyle: { color: "#FFF" },
          data: uniqueAsesorIds,
          type: "scroll",
          pageIconColor: "#FFF",
          pageTextStyle: { color: "#FFF" },
          show: true,
        },

        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          borderColor: "#FFF",
          borderWidth: 1,
          textStyle: { color: "#FFF" },
          axisPointer: { type: "shadow" },
        },
        grid: { left: 50, right: 30, top: 50, bottom: 50 },
        yAxis: {
          type: "value",
          axisLabel: { color: "#FFF" },
          splitLine: { lineStyle: { color: "rgba(255, 255, 255, 0.2)" } },
        },
        xAxis: {
          type: "category",
          data: uniqueDates,
          axisLabel: { color: "#FFF" },
        },
        series,
        animationDuration: 1000,
      };

      myChart.setOption(option);

      const handleResize = () => {
        myChart.resize();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        myChart.dispose();
      };
    }
  }, [dataAdvisorsStackBar]);

  return <div ref={chartRef} style={{ width: "100%", height: "450px" }} />;
};

export default EchartsStackBar;
