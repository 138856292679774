import {
  GeneralConfigByClient,
  PricingConfig,
  Service,
} from "../../BillingAPI";
import { formatCurrency } from "../../helpers/utils";
import "../../BillingStyles.scss";

interface ServiceProps {
  serviceName: string;
  service: Service;
}

const ServiceCard: React.FC<ServiceProps> = ({ serviceName, service }) => {
  return (
    <div>
      <div className="service-card">
        <h6 className="service-card__title">{serviceName}</h6>
        <div>
          <div>
            <p className="service-card__label">Inicio Cobro</p>
            <p className="service-card__value">{service.startBilling}</p>
          </div>
          {service.maxBilling && (
            <div>
              <p className="service-card__label">
                Máximo registros facturables
              </p>
              <p className="service-card__value">{service.maxBilling}</p>
            </div>
          )}
          <div>
            <p className="service-card__label">
              Valor unitario: {service.currency}
            </p>
            <PriceContainer pricingConfig={service.unitPrice} />
          </div>
          {service.secondaryUnitPrice && (
            <div>
              <p className="service-card__label">
                Valor unitario secundario: {service.currency}
              </p>
              <PriceContainer pricingConfig={service.secondaryUnitPrice} />
            </div>
          )}
        </div>
        {service.constraints.length > 0 && (
          <div>
            {service.constraints.map((constraint, index) => (
              <div key={index}>
                <p className="service-card__label">
                  {constraint.constraintType}
                </p>
                <ul className="service-card__constraint-list">
                  {constraint.constraintEntityIds.map((contrainsId) => (
                    <li key={contrainsId}> {contrainsId}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const ClientConfig: React.FC<{
  config: GeneralConfigByClient | null;
}> = ({ config }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
      <div className="client-config__services">
        {config &&
          Object.entries(config.services).map(([serviceName, service]) => (
            <ServiceCard
              key={serviceName}
              serviceName={serviceName}
              service={service}
            />
          ))}
        <div className="client-config__cutoff">
          <p className="client-config__title">Fecha de corte de facturación</p>
          <p className="service-card__label">Día de Corte</p>
          <p className="client-config__value">{config?.cutoffDay}</p>
        </div>
      </div>
    </div>
  );
};

export const PriceContainer: React.FC<{ pricingConfig: PricingConfig }> = ({
  pricingConfig,
}) => {
  if (pricingConfig.scalable) {
    return (
      <div className="service-card__value">
        {pricingConfig.pricingItems.map((item, index) => (
          <div key={index}>
            <p className="service-card__label">{`Consumos entre ${item.min} y ${item.max}`}</p>
            <p className="service-card__value">{formatCurrency(item.price)}</p>
          </div>
        ))}
      </div>
    );
  } else {
    const pricing = pricingConfig.pricingItems[0].price;
    return <p className="service-card__value">{formatCurrency(pricing)}</p>;
  }
};
