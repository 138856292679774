import React from "react";

interface IndicatorButtonProps {
  isActive: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  isLeft?: boolean;
  icon?: React.SVGProps<SVGAElement>;
}

export const IndicatorButton: React.FC<IndicatorButtonProps> = ({
  isActive,
  onClick,
  label,
  isLeft,
  icon,
}) => (
  <button
    type="button"
    style={{
      backgroundColor: isActive ? "rgb(0,0,0,0.5)" : "rgb(0,0,0,0.2)",
      color: "#ffffff",
      minWidth: "30px",
      borderRadius: isLeft ? "5px 0 0 5px" : "0 5px 5px 0",
      border: "none",
    }}
    onClick={onClick}
    aria-label={label}
  >
    {icon ? icon : label}
  </button>
);
