export const transformDataWithAdvisors = (
  data: DataAdvisors[] | undefined,
  advisors: Record<string, string> | null
) => {
  return (
    data?.map((advisor) => ({
      ...advisor,
      _id: {
        asesorId:
          (advisors ?? {})[advisor._id.asesorId] || advisor._id.asesorId,
        year: advisor._id.year,
        month: advisor._id.month,
        day: advisor._id.day,
      },
      count: advisor.count,
    })) ?? []
  );
};

export const monthNames = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

export const transformResponse = (
  data: DataAdvisorResponse | undefined,
  advisors: Record<string, string> | null,
  hyperFlows?: Record<string, string> | null,
  flowStatusMap?: Record<string, string> | null
): DataAdvisorResponse => {
  if (!data) return {} as DataAdvisorResponse;

  const newData = { ...data };

  if (newData.flowId) {
    newData.flowId = newData.flowId.map((item) => ({
      ...item,
      _id: {
        ...item._id,
        asesorId: advisors?.[item._id.asesorId] ?? item._id.asesorId,
        hyperFlowId: hyperFlows?.[item._id.hyperFlowId] ?? item._id.hyperFlowId,
      },
    }));
  }

  if (newData.flowStatus) {
    newData.flowStatus = newData.flowStatus.map((item) => ({
      ...item,
      _id: {
        ...item._id,
        asesorId: advisors?.[item._id.asesorId] ?? item._id.asesorId,
        flowStatus: flowStatusMap?.[item._id.flowStatus] ?? item._id.flowStatus,
        acceptanceStatus:
          flowStatusMap?.[item._id.acceptanceStatus] ??
          item._id.acceptanceStatus,
      },
    }));
  }

  return newData;
};

interface DataAdvisors {
  _id: {
    asesorId: string;
    year: number;
    month: number;
    day: number;
  };
  count: number;
  [key: string]: any;
}

interface DataFlowId {
  _id: {
    asesorId: string;
    hyperFlowId: string;
  };
  count: number;
}

interface DataFlowStatus {
  _id: {
    asesorId: string;
    flowStatus: string;
    acceptanceStatus: string;
  };
  count: number;
}
interface DataAdvisorResponse {
  flowId?: DataFlowId[];
  flowStatus?: DataFlowStatus[];
}
